<template>
   <div class="print_invoice_money_details_design_4" id="print_invoice_money_details_design_4">
      <div class="row justify-content-between mb-3">
         <!-- debts  -->
         <div class="col-4">
            <div class="info-holder">
               <span class="label">{{ $t('lang_old_balance') }}</span> :
               <span class="number">{{ $filters.setCurrency(old_debts, currency) }}</span>
            </div>

            <div class="info-holder">
               <span class="label">{{ $t('lang_current_balance') }}</span> :
               <span class="number">{{ $filters.setCurrency(total_debts, currency) }}</span>
            </div>
         </div>
         
         <!-- singles & cartons  -->
         <div class="col-4">
            <div class="info-holder">
               <span class="label">{{ $t('lang_one_piece_number') }}</span> :
               <span class="number">{{ singles }}</span>
            </div>

            <div class="info-holder">
               <span class="label">{{ $t('lang_carton_number') }}</span> :
               <span class="number">{{ cartons }}</span>
            </div>
         </div>

         <!-- total  -->
         <div class="col-4">
            <div class="info-holder">
               <span class="label">{{ $t('lang_total_amount') }}</span> :
               <span class="number">{{ $filters.setCurrency(total_invoice + discount, currency) }}</span>
            </div>

            <div class="info-holder">
               <span class="label">{{ $t('lang_discount_price') }}</span> :
               <span class="number">{{ $filters.setCurrency(discount, currency) }}</span>
            </div>

            <div class="info-holder">
               <span class="label">{{ $t('lang_payed') }}</span> :
               <span class="number">{{ $filters.setCurrency(payed, currency) }}</span>
            </div>

            <div class="info-holder">
               <span class="label">{{ $t('lang_net_invoice') }}</span> :
               <span class="number">{{ $filters.setCurrency(total_invoice, currency) }}</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      currency: String,
      has_discount:  {type: Boolean, default: false },
      discount:      {type: [String, Number], default: 0 },
      total_invoice: {type: [String, Number],default: 0},
      invoice_type:  {type: String,default: ''},
      singles:       {type: [String, Number],default: 0},
      cartons:       {type: [String, Number],default: 0},
      old_debts:     {type: [String, Number],default: 0},
      total_debts:   {type: [String, Number],default: 0},
      payed:         {type: [String, Number],default: 0},
      remain:        {type: [String, Number],default: 0},
   },
}
</script>


<style scoop lang="scss">
   @use '@sass/helpers' as *;

   .print_invoice_money_details_design_4 {
      .info-holder {
         display: flex;
         align-items: center;
         gap: rem(5);
         margin-top: rem(5);
         font-size: rem(13);
         
         .label {
            width: rem(80);
         }

         .number {
            width: rem(150);
            padding: 0 rem(5);
            border: solid rem(1) #777;
            text-align: center;
            background-color: rgba(119, 119, 119, .3);
         }
      }
   }

</style>