<template>
   <div class="print_footer_design_4" id="print_footer_design_4">
      <hr class="hr">

      <div class="info">
         <div v-if="username" class="d-flex gap-2 align-items-center">
            <span class="label">{{ $t('lang_username') }}</span> :
            <span>{{ username }}</span>
         </div>
         <span>{{ date }}</span>
      </div>
      
   </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
   props: {
      username: {type: [String, Boolean], default: false},
   },
   setup () {
      const store = useStore();
      const shop  = store.getters['shop/getShop'];

      const state = reactive({
         shopPhone: shop.phone || false,
         shopAddress: shop.address || false,
         shopSecondAddress: shop.secondaryAddress || false,
         shopFacebook: shop.facebook || false,
         shopInstagram: shop.instagram || false,
         shopTelegram: shop.telegram || false,
         shopWhatsapp: shop.whatsapp || false,
         shopNote: shop.note || false,
         date: getDate(),
      });

      return {
         ...toRefs(state),
      }
   }
}
</script>

<style lang="scss" scoped>
@use '@sass/helpers' as *;

.print_footer_design_4 {
   padding: 1rem;
   margin-top: auto;
   font-size: 1rem;
   color: #222;

   .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
   }

   .hr {
      margin: .5rem 0;
      opacity: .8;
   }


}
</style>