const mainTableClassBackground = 'table-dark';

const tables = {
   // ~~~~~~~~~~~~~> add default styling classes to table & some events on his elements
   table : {
      mounted(el) {
         let table = el;

         table.querySelectorAll('td').forEach(td => {
            if (td.querySelector('input[data-for-deleting]')  || td.hasAttribute('data-normal-td')) return;
            td.onclick = () => {
               if (td.classList.contains('off-unset-mw')) return;
               td.classList.toggle('unset-mw');
            }
         });

         if (table.querySelector('[data-for-deleting]')) {
            table.querySelectorAll('[data-for-deleting]').forEach(input => {
               input.classList.remove('form-check-input');
               input.classList.add('form-check-input');
            });
         }

         if (!table.classList.contains('my-2') && !table.classList.contains('my-0')) table.classList.add('my-2');
         if (!table.classList.contains('table-borderless')) table.classList.add('table-bordered');
         if (!table.classList.contains('table-no-striped')) {
            table.classList.remove('table-striped');
            table.classList.add('table-striped');
         }
         // if (!table.classList.contains('table-no-sm')) {
         //    table.classList.remove('table-sm');
         //    table.classList.add('table-sm');
         // }


         if (
            table.classList.contains('table-white')     ||
            table.classList.contains('table-light')     ||
            table.classList.contains('table-dark')      ||
            table.classList.contains('table-primary')   ||
            table.classList.contains('table-secondary') ||
            table.classList.contains('table-warning')   ||
            table.classList.contains('table-info')      ||
            table.classList.contains('table-success')   ||
            table.classList.contains('table-danger')
         ) return;

         table.classList.remove(mainTableClassBackground);
         table.classList.add(mainTableClassBackground);

         if (!table.classList.contains('table-dark')) {
            if (table.querySelector('[tooltip-overflow]')) {
               table.querySelectorAll('[tooltip-overflow]').forEach(tooltip => tooltip.removeAttribute('tt-light'));
            }
         }

      },
      updated(el) {
         let table = el;

         table.querySelectorAll('td').forEach(td => {
            if (td.querySelector('input[data-for-deleting]') || td.hasAttribute('data-normal-td')) return;
            td.onclick = () => td.classList.toggle('unset-mw');
         });

         if (table.querySelector('[data-for-deleting]')) {
            table.querySelectorAll('[data-for-deleting]').forEach(input => {
               input.classList.remove('form-check-input');
               input.classList.add('form-check-input');
            });
         }

         if (!table.classList.contains('my-2') && !table.classList.contains('my-0')) table.classList.add('my-2');
         if (!table.classList.contains('table-borderless')) table.classList.add('table-bordered');
         if (!table.classList.contains('table-no-striped')) {
            table.classList.remove('table-striped');
            table.classList.add('table-striped');
         }
         // if (!table.classList.contains('table-no-sm')) {
         //    table.classList.remove('table-sm');
         //    table.classList.add('table-sm');
         // }

         if (
            table.classList.contains('table-white')     ||
            table.classList.contains('table-light')     ||
            table.classList.contains('table-dark')      ||
            table.classList.contains('table-primary')   ||
            table.classList.contains('table-secondary') ||
            table.classList.contains('table-warning')   ||
            table.classList.contains('table-info')      ||
            table.classList.contains('table-success')   ||
            table.classList.contains('table-danger')
         ) return;

         table.classList.remove(mainTableClassBackground);
         table.classList.add(mainTableClassBackground);


         if (!table.classList.contains('table-dark')) {
            if (table.querySelector('[tooltip-overflow]')) {
               table.querySelectorAll('[tooltip-overflow]').forEach(tooltip => tooltip.removeAttribute('tt-light'));
            }
         }
      },
   },

   // ~~~~~~~~~~~~~> to make all rows selected in table for deleting
   selectAll : {
      mounted(checkBox) {
         checkBox.addEventListener('click', _=> {
            if (checkBox.checked) el('table tbody [data-for-deleting]', true).forEach(el => {
               if (!el.checked) setTimeout(() => el.click() , 0);
            });
            else el('table tbody [data-for-deleting]', true).forEach(el => {
               if (el.checked) setTimeout(() => el.click() , 0);
            })
         });
      }
   },

   tablePrintDesign: {
      mounted(el, binding) {
         let design = binding.value;
         let table  = el;
         let thead  = table.querySelector('thead');
         let tbody  = table.querySelector('tbody');

         if (!table.classList.contains('table')) table.classList.add('table');
         if (!table.classList.contains('table-hover')) table.classList.add('table-hover');
         if (!table.classList.contains('my-3')) table.classList.add('my-3');


         if (design == '1') {
            if (!table.classList.contains('table-white')) table.classList.add('table-white');
            if (!table.classList.contains('border-dark')) table.classList.add('border-dark');
            if (!table.classList.contains('table-borderless')) table.classList.add('table-bordered');

            if (!thead.classList.contains('bg-secondary')) thead.classList.add('bg-secondary');
            thead.style.setProperty('--bs-bg-opacity', '.5');
         }

         if (design == '2') {
            if (!table.classList.contains('table-white')) table.classList.add('table-white');
            if (!table.classList.contains('border-dark')) table.classList.add('border-dark');
            if (!table.classList.contains('table-borderless')) table.classList.add('table-bordered');

            if (!thead.classList.contains('bg-print-main-clr')) thead.classList.add('bg-print-main-clr');
         }

         if (design == '3') {
            if (!table.classList.contains('table-white')) table.classList.add('table-white');
            if (!table.classList.contains('border-dark')) table.classList.add('border-dark');
            if (!table.classList.contains('table-borderless')) table.classList.add('table-bordered');

            if (!thead.classList.contains('bg-print-d3-clr')) {
               thead.classList.add('bg-print-d3-clr');
               thead.classList.add('text-white');

            }
         }

         if (design == '4') {
            if (!table.classList.contains('table-white')) table.classList.add('table-white');
            if (!table.classList.contains('border-dark')) table.classList.add('border-dark');
            if (!table.classList.contains('table-borderless')) table.classList.add('table-bordered');
         }
         
      },
   },

   tablePrintDesignHeadBgClr: {
      mounted(el, binding) {
         let design  = binding.value;
         let element = el;

         if (design == '1') {
            if (!element.classList.contains('bg-secondary')) element.classList.add('bg-secondary');
            element.style.setProperty('--bs-bg-opacity', '.5');
         }
         
         if (design == '2') {
            if (!element.classList.contains('bg-print-main-clr')) element.classList.add('bg-print-main-clr');
         }

         if (design == '3') {
            if (!element.classList.contains('bg-print-d3-clr')) {
               element.classList.add('bg-print-d3-clr');
               element.classList.add('text-white');
            }
         }
         
      },
   },

   tablePrintDesignTextClr: {
      mounted(el, binding) {
         let design  = binding.value;
         let element = el;

         if (design == '1') {
            if (!element.classList.contains('text-dark')) element.classList.add('text-dark');
            if (
               !element.classList.contains('border') &&
               !element.classList.contains('border-dark') &&
               !element.classList.contains('no-border')

            ) element.classList.add('border', 'border-dark');
         }
         
         if (design == '2') {
            if (!element.classList.contains('text-print-main-clr')) element.classList.add('text-print-main-clr');
            if (!element.classList.contains('bg-dark') && !element.classList.contains('no-bg')) element.classList.add('bg-dark');
         }

         if (design == '3') {
            if (!element.classList.contains('text-print-d3-clr')) {
               element.classList.add('text-print-d3-clr');
            }
         }
         
      },
   },
};


export default tables;
