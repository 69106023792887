
<template>
   <div class="print_invoice_words_design_2" id="print_invoice_words_design_2">
      <fieldset>
         <legend class="fs-16">{{ $t('lang_notes') }}</legend>
         <p class="note">{{ note }}</p>
      </fieldset>
   </div>
</template>

<script>
export default {
   props: {
      note: { type: String, default: '' },
   },
}
</script>

<style scoped lang="scss">
@use '@sass/helpers' as *;

.print_invoice_words_design_2 {
   display: grid;
   gap: rem(5);
   font-size: rem(13);
   margin-bottom: rem(10);

   .note {
      padding: rem(5);
   }
}


</style>