
<template>
   <div class="print_invoice_words_design_4" id="print_invoice_words_design_4">
      <p class="totalInvoiceInWords">
         <span class="label">{{ $t('lang_total') }}  : </span>
         <span class="number">{{ invoice_result }} </span>
      </p>
      <fieldset>
         <legend class="fs-16">{{ $t('lang_notes') }}</legend>
         <p class="note">{{ note }}</p>
      </fieldset>
   </div>
</template>

<script>
import { reactive, toRefs} from 'vue';
import functions from '@/utils/functions';


export default {
   props: {
      note: { type: String, default: '' },
      currency: { type: String, default: 'dinar' },
      totalInvoice: { type: [String, Number], default: 0 }
   },

   setup(props) {
      const state = reactive({
         invoice_result: numberToWords(props.totalInvoice, props.currency == 'dinar' ? 'IQD' : 'USD'),
      });

      
      return {
         ...toRefs(state),
      }
   }
}
</script>

<style scoped lang="scss">
@use '@sass/helpers' as *;

.print_invoice_words_design_4 {
   display: grid;
   gap: rem(5);
   font-size: rem(13);
   margin-bottom: rem(10);

   .totalInvoiceInWords {
      display: flex;
      align-items: center;
      gap: rem(5);

      .label {
         width: rem(80);
      }

      .number {
         padding-bottom: rem(2.5);
         font-size: rem(15);
         font-weight: bold;
         border-bottom: solid rem(4) rgba(54, 54, 237, 0.743);
      }
   }

   .note {
      padding: rem(5);
   }
}


</style>