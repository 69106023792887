<template>
   <div class="print_header_design_4" id="print_header_design_4">
      <div class="info">
         <div class="logo">
            <img :src="`${URL_PATH + UPLOADS_IMG_FOLDER_NAME}shop/${shopImg}`" class="rounded-img-100 no-img-loader" alt="Shop Logo">
            <h2 class="title">{{ shopName }}</h2>

            <!-- phone  -->
            <div v-if="shopPhone" class="contact">
               <span>{{ $t('lang_phone') }}</span> : 
               <span>{{ shopPhone  }}</span>
            </div>
         </div>

         <!-- social -->
         <div class="social" v-if="shopFacebook || shopInstagram || shopTelegram || shopWhatsapp">
            <div class="social_row">
               <div v-if="shopFacebook">
                  <i class="fa-brands fa-facebook"></i>
                  <span>{{ shopFacebook }}</span>
               </div>
               <div v-if="shopInstagram">
                  <i class="fa-brands fa-instagram"></i>
                  <span>{{ shopInstagram }}</span>
               </div>
            </div>
            <div class="social_row">
               <div v-if="shopWhatsapp">
                  <i class="fa-brands fa-whatsapp"></i>
                  <span>{{ shopWhatsapp }}</span>
               </div>
               <div v-if="shopTelegram">
                  <i class="fa-brands fa-telegram"></i>
                  <span>{{ shopTelegram }}</span>
               </div>
            </div>
         </div>

         <div>
            <img :src="`${URL_PATH + UPLOADS_IMG_FOLDER_NAME}shop/${shopSecondImg}`" class="rounded-img-100 no-img-loader mx-auto" alt="Shop Logo">
            
            <!-- address  -->
            <div v-if="shopAddress" class="contact">
               <i class="fa-solid fa-location-dot"></i>
               <span>{{ shopAddress }}</span>
            </div>
            <!-- secondary address  -->
            <div v-if="shopSecondAddress" class="contact">
               <i class="fa-solid fa-location-dot"></i>
               <span>{{ shopSecondAddress }}</span>
            </div>
         </div>
      </div>

      <hr class="hr">
      <p class="desc">{{ titleMsg  }}</p>
   </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n';

export default {
   props: {
      currency: String,
      invoiceNumber: {
         type: [String, Boolean],
         default: false,
      },
      title: {
         type: [String, Boolean],
         default: false,
      },
      date: {
         type: String,
         default: getDate(),
      },
      time: {
         type: String,
         default: getTime(),
      }
   },
   setup (props) {
      const store = useStore();
      const { t } = useI18n();
      const shop  = store.getters['shop/getShop'];

      const state = reactive({
         shopName: shop.name || false,
         shopImg: shop.image || false,
         shopSecondImg: shop.secondaryImage || false,
         shopPhone: shop.phone || false,
         shopAddress: shop.address || false,
         shopSecondAddress: shop.secondaryAddress || false,
         shopFacebook: shop.facebook || false,
         shopInstagram: shop.instagram || false,
         shopTelegram: shop.telegram || false,
         shopWhatsapp: shop.whatsapp || false,

         URL_PATH,
         UPLOADS_IMG_FOLDER_NAME,
      });

      const currencyName = computed(()=> {
         if (props.currency == 'dollar') return 'lang_in_dollar';
         else return 'lang_in_dinar';
      });

      const titleMsg = computed(()=> {
         if (props.title == false) return shop.description;
         else return t(props.title);
      });
   
      return {
         ...toRefs(state),

         currencyName,
         titleMsg,
      }
   }
}
</script>


<style lang="scss" scoped>
@use '@sass/helpers' as *;

.print_header_design_4 {
   display: grid;
   gap: rem(10);
   margin-bottom: rem(20);
   min-height: rem(150);
   height: fit-content;

   .info {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      > * {
         display: grid;
         gap: rem(8);
      }
   }

   .hr {
      margin: .5rem 0;
      opacity: .8;
   }

   .desc {
      text-transform: capitalize;
      text-align: center;
      font-weight: bold;
   }

   .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: rem(20);
   }
   
   .info-label {
      margin-bottom: rem(5);
      span {
         text-transform: capitalize;
      }
      .label {
         color: #222;
      }
   }

   .contact ,
   .social {
      color: #222;
   }
}
</style>