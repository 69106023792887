<template>
   <div class="print_invoice_contact_details_design_4" id="print_invoice_contact_details_design_4">
      <div class="row justify-content-between">
         <div class="col-6">
            <!-- supplier  -->
            <div v-if="supplier" class="info-holder">
               <span class="label">{{ $t('lang_supplier_name') }}</span>
               <span class="name">{{ supplier.SpName }}</span>
            </div>
            <!-- customer  -->
            <div v-if="customer" class="info-holder">
               <span class="label">{{ $t('lang_the_client') }}</span>
               <span class="name">{{ customer.CustName }}</span>
            </div>
            <!-- driver  -->
            <div  v-if="driver" class="info-holder">
               <span class="label">{{ $t('lang_driver_name') }}</span>
               <span class="name">{{ driver.DName }}</span>
            </div>
            <!-- delegates  -->
            <div v-if="delegate" class="info-holder">
               <span class="label">{{ $t('lang_delegate') }}</span>
               <span class="name">{{ delegate.DelName }}</span>
            </div>
         </div>

         <div class="col-5">
            <!-- invoice number  -->
            <div v-if="invoiceNumber" class="info-holder">
               <span class="label">{{ $t('lang_invoice_number') }}</span>
               <span class="name">{{ invoiceNumber }}</span>
            </div>
            <!-- invoice date  -->
            <div v-if="date" class="info-holder">
               <span class="label">{{ $t('lang_invoice_date') }}</span>
               <span class="name">{{ date }}</span>
            </div>
            <!-- customer phone  -->
            <div v-if="customer" class="info-holder">
               <span class="label">{{ $t('lang_phone_number') }}</span>
               <span class="name">{{ customer.CustPhone }}</span>
            </div>
            <!-- supplier phone  -->
            <div v-if="supplier" class="info-holder">
               <span class="label">{{ $t('lang_phone_number') }}</span>
               <span class="name">{{ supplier.SpPhone }}</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      customer:      { type: [Object, Boolean], default: false },
      supplier:      { type: [Object, Boolean], default: false },
      driver:        { type: [Object, Boolean], default: false },
      delegate:      { type: [Object, Boolean], default: false },
      invoiceNumber: { type: [String, Boolean], default: false },
      date:          { type: String, default: getDate() },
      time:          { type: String, default: getTime() },
      invoice_type: String,
      currency: String,
   },

   setup(props) {
      if (isEmpty(props.driver)) props.driver    = false;
      if (isEmpty(props.delegate)) props.delegate = false;
   }
}
</script>

<style scoped lang="scss">
@use '@sass/helpers' as *;

.print_invoice_contact_details_design_4 {
   height: fit-content;
   font-size: rem(13);

   .info-holder {
      display: flex;
      gap: rem(10);
      margin-bottom: rem(5);

      > * {
         padding: rem(2.5) rem(5);
         text-align: center;
         border: solid rem(1) #222;
         display: grid;
         place-items: center;
      }
      
      .name {
         flex: 1;
      }

      .label {
         width: rem(120);
         text-align: center;
      }
   }
}


</style>